import { getDynamicEnvVarsInClient } from '@/utils/envHelper';
import axios from 'axios';

export async function fetchAllUsers(
  tenantId: any,
  tenantName: any,
  accessToken: any,
  all_user: boolean,
  base_url: string
) {
  try {
    const response = await axios.get(`${base_url}/users`, {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': tenantId,
        'X-TENANT-NAME': tenantName,
        ...(all_user ? { all_user: all_user } : {}),
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data?.data;
  } catch (error) {
    console.log('Error fetching users', error);
    return [];
  }
}

export async function deleteUser(
  userId: string,
  tenantId: any,
  tenantName: any,
  accessToken: any
) {
  const { NEXT_PUBLIC_CW_AUTH_URL } = getDynamicEnvVarsInClient();
  try {
    const response = await axios.delete(
      `${NEXT_PUBLIC_CW_AUTH_URL}/users/${userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error deleting user with ID ${userId}:`, error);
  }
}

export async function logoutUser(
  userId: string,
  tenantId: any,
  tenantName: any,
  accessToken: any
) {
  const { NEXT_PUBLIC_CW_AUTH_URL } = getDynamicEnvVarsInClient();
  try {
    const response = await axios.get(`${NEXT_PUBLIC_CW_AUTH_URL}/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': tenantId,
        'X-TENANT-NAME': tenantName,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error logout user with ID ${userId}:`, error);
  }
}

export async function getUserByToken(
  tenantId: string,
  tenantName: string,
  token: string,
  base_url: string
) {
  if (
    tenantId &&
    tenantId !== 'null' &&
    tenantName &&
    tenantName !== 'null' &&
    token &&
    token !== 'null'
  ) {
    try {
      const response = await axios.get(`${base_url}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-TENANT-ID': tenantId,
          'X-TENANT-NAME': tenantName,
        },
      });
      return response?.data?.data;
    } catch (error) {
      console.error(`Error in getting user by token:`, error);
    }
  }
}

export async function isMySessionValid(
  tenantId: string,
  tenantName: string,
  token: string,
  base_url: string
) {
  try {
    const response = await axios.get(`${base_url}/validate/session`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-TENANT-ID': tenantId,
        'X-TENANT-NAME': tenantName,
      },
    });
    return response?.data?.data;
  } catch (error) {
    throw new Error('Token expired.');
  }
}

export async function getAccessTokenFromRefreshToken(
  refreshToken: string,
  base_url: string
) {
  try {
    const response = await axios.post(
      `${base_url}/token/generate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate access token');
  }
}

export async function fetchPermissions(
  tenantId: any,
  tenantName: any,
  accessToken: any,
  base_url: string
) {
  try {
    const response = await axios.get(`${base_url}/me/permission`, {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': tenantId,
        'X-TENANT-NAME': tenantName,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting user's permission:`, error);
  }
}
